import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, StyleRules } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import fintegrity from '../images/0419Fintegrity_logo-03.png';
import { AppState } from '../reducers';
import { FUserTypeEnum } from '../reducers/userReducer';

const styles = (): StyleRules =>
  createStyles({
    root: {
      marginBottom: 0,
      position: 'absolute',
      left: '50%',
      top: '35%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
    },
    loadingText: { textAlign: 'center', marginTop: '2rem', color: 'black', fontSize: '2rem' },
  });

const LoadingLogo = ({ classes }: Props): React.ReactElement => {
  const user = useSelector((appState: AppState) => appState.user);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval((): void => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
    return (): void => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className={classes.root}>
        <img src={fintegrity} style={{ width: '200px' }}></img>
        <p className={classes.loadingText}>
          {user.userType == FUserTypeEnum.COMPANY
            ? '企业管理中心'
            : user.userType == FUserTypeEnum.CONSULTANT
            ? '傅通自由职业'
            : null}
        </p>
        <LinearProgress
          color="secondary"
          value={progress}
          style={{ width: '70%', left: '15%', position: 'relative' }}
          variant="determinate"
        />
      </div>
    </>
  );
};

export type Props = WithStyles<typeof styles>;

export default withStyles(styles)(LoadingLogo);
